document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {
        //Set the first element as active and show content
        $('.story-buttons button').first().removeClass('disabled-btn');
        $('.story-buttons button').first().addClass('active-btn');
        $('#top div').first().removeClass('hidden');
        $('#top div').first().addClass('active');

            $('.story-buttons button').on("click", function(e){
                $(this).siblings().removeClass('active-btn');
                $(this).siblings().addClass('disabled-btn');
                $(this).removeClass('disabled-btn');
                $(this).addClass('active-btn');
                var sel_id = $(this).attr('id');
                $('#top .' + sel_id).siblings().removeClass('active');
                $('#top .' + sel_id).siblings().addClass('hidden');
                $('#top .' + sel_id).removeClass('hidden');
                $('#top .' + sel_id).addClass('active');
            });
            
        //Read more scroll to top
        $('.readmore-buttons button').on("click", function(){
            $('html, body').animate({
                scrollTop: $(".consumer-hero-title-block").offset().top
            }, 1500);
            var sel_id = $(this).attr('id');
            $('.story-buttons #' + sel_id).siblings().removeClass('active-btn');
            $('.story-buttons #' + sel_id).siblings().addClass('disabled-btn');
            $('.story-buttons #' + sel_id).removeClass('disabled-btn');
            $('.story-buttons #' + sel_id).addClass('active-btn');
            $('#top .' + sel_id).siblings().removeClass('active');
            $('#top .' + sel_id).siblings().addClass('hidden');
            $('#top .' + sel_id).removeClass('hidden');
            $('#top .' + sel_id).addClass('active');
        });
    });
})(jQuery);